*, *:before, *:after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
body {
  background-image: url(/src/assets/background.jpg);
  background-attachment: fixed;
  background-color: #021321;
  background-size: cover;
  font-family: Rubik,sans-serif;
  font-size: 13px;
  line-height: 1.5;
}
header {
  border-bottom: 1px solid #ffffff1f;
}
.menu-control {
  background: none;
  border: none;
  color: #09ebc5;
  font-size: 21px;
}
.menu-control:before {
  content: "\e6c2";
  font-family: themify;
}
/* card styles */
.card-style-2 {
  background: #0a112ad9;
  border-radius: 16px;
  padding: 26px;
  height: 100%;
}
.card-heading h2 {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.network-simulation, .bridge-calculator {
	counter-reset: h2counter;
}
.card-heading:before {
	counter-increment: h2counter;
	content: counters(h2counter,".") "";
	color: white;
	background: #b13097;
	min-width: 38px;
	display: inline-block;
	height: 38px;
	text-align: center;
	line-height: 38px;
	border-radius: 5px;
	font-size: 16px;
	margin-right: 12px;
}
.card-style-2 h3 {
  font-size: 18px;
  font-weight: 500;
  color: #33ccee;
  margin-top: 1rem;
  margin-bottom: 20px;
}
.card-heading p {
  color: #8ba3a3;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.card-heading {
  padding-bottom: 22px;
  align-items: center !important;
  justify-content: flex-start !important;
  display: flex !important;
}
/* card styles */
/* results */
.results {
  color: white;
}

.results p {
  color: #13e4fe;
  font-size: 16px;
}

.result-box {
  padding: 2rem;
  text-align: center;
  border-radius: 9px;
  background: #141938;
  font-size: 16px;
  font-weight: 300;
  margin: 8px 0;
}
.result-box h4 {
  width: 100%;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}
/* results */
/* page title */
.page-title {
  background: #0a112ad9;
  border-radius: 16px;
  color: #fff;
  padding: 32px 26px
}

.page-title h1 {
  font-size: 28px;
  margin: 0
}

.page-title p {
  color: #8ba3a3;
  margin: 0
}

.title-icon {
  background: #b13097;
  border-radius: 9px;
  font-size: 26px;
  height: 56px;
  margin-right: 14px;
  min-width: 54px;
  padding: 8px 12px;
  text-align: center
}
/* page title */

/* faqs */
.faq-accordion {
  background: 0 0!important;
  counter-reset: accordioncounter
}

.faq-accordion .accordion-item {
  background: #0a112ad9;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  margin-bottom: 15px
}

.faq-accordion .accordion-item .accordion-body {
  border: 0;
  padding: 0 20px 20px
}

.faq-accordion .accordion-header button {
  background: 0 0;
  border: 0;
  box-shadow: none;
  color: #0fd9ff;
  font-size: 17px;
  padding: 24px 52px 24px 20px
}

.faq-accordion .accordion-header button:after {
  background: no-repeat;
  content: "\f078";
  font-family: FontAwesome;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 20px;
  text-align: center;
  width: 24px
}

.faq-accordion .accordion-header button:before {
  color: #fff;
  content: counters(accordioncounter,".") ".";
  counter-increment: accordioncounter;
  margin: 0 1rem
}
/* faqs */
/* offcanvas */
.offcanvas-menu {
  background: #070f25;
  max-width: 80%;
}

.offcanvas-menu:after {
  mix-blend-mode: screen;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../src/assets/bg-1.png) no-repeat top center transparent;
  background-size: cover;
  top: 0;
  z-index: -1;
  opacity: 0.3;
}

.offcanvas-menu:before {
  background: url(../src/assets/body-bg.png) no-repeat 0 0 #021321;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.6;
}

.mobile-nav {
  display: block;
  margin-bottom: 2rem;
}

.mobile-nav li a {
  padding: 16px 0px;
  display: block;
  border-bottom: 1px solid #ffffff14;
  color: white;
}

.offcanvas-header {
  color: white;
}

.offcanvas-header h5 {
  font-weight: 500;
}

.offcanvas-body {
  padding: 0px 20px;
}

.close-button {
  background: transparent;
  color: #29fff1;
  font-size: 24px;
  height: 30px;
  line-height: 26px;
  width: 30px;
  display: inline-block;
  border: 0;
  margin: 0;
  padding: 0;
}
.close-button i {
  margin: 0;
  padding: 0;
}
.close-button:hover {
  opacity: 1;
}
/* offcanvas */

.title {
  color: white;
  font-size: 15px;
  font-weight: 500;
}

.button {
  transition: all .4s ease-out 0s;
}
.button-primary {
  color: white;
  background: #1b399a;
  padding: 16px 17px;
  font-size: 14px;
  border: 0;

}
.button-primary:hover, .button-primary:active {
  background: #1a3db1;
  color: white !important;
  border: 0;
  }
.back-home {
  border: 2px solid #33d1eb;
  border-radius: 36px;
  color: #33d1eb;
  font-weight: 500;
  height: 44px;
  line-height: 1;
  padding: 14px 2rem;
}

.back-home:hover {
  border: 2px solid #51dff7;
  color: #51dff7;
}

.options .btn {
  width: 100%;
  margin-bottom: 18px;
}
.options .btn:focus {
  box-shadow: 0 0 0 0.2rem rgb(27 25 155 / 21%) !important;
}
.button-primary-personal{
  border: 2px solid #33d1eb;
  border-radius: 10px;
  transition: .5s;
  font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    color: #33d1eb;
    font-weight: 500;
    height: 100%;
    line-height: 1;
    margin:5px;
    background: transparent;
}

.button-primary-personal:hover {
  background: #03e9f4;
  border: 2px solid #33d1eb;
  border-radius: 10px;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4; 
}
.disclaimer-body {
  color: white;
  font-size: 14px;
  line-height: 1.5;
}

.disclaimer-body p {
  color: #838d8d;
  line-height: 2;
}




.bridge-calculator h4 {
  color: white;
  font-size: 15px;
  font-weight: 200;
}

.bridge-calculator .user-box {
  position: relative;
}

.bridge-calculator .user-box input {
  width: 100%;
  padding: 14px 20px;
  color: #fff;
  margin-bottom: 20px;
  border: none;
  outline: none;
  background: #141938;
  font-size: 13px;
  border-radius: 6px;
  color: #cacaca;
}

.bridge-calculator .user-box label {
  color: #cbd5db;
  font-size: 13px;
  margin-bottom: 4px;
  align-items: center!important;
}

.bridge-calculator .user-box input:focus ~ label,
.bridge-calculator .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}




.choose-section {
  color: white;
  text-align: center;
}
.choose-header p {
    font-size: 15px;
    color: #b7b7c7;
}

.choose-header {
    margin-bottom: 3rem;
}
.choose-section i {
  font-size: 34px;
  color: #30d6be;
  height: 40px;
  line-height: 40px;
}

.choose-section h2 {
  font-weight: 400;
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 20px;
}


.choose-section h2:hover {
  color: #30d6be;
}
.choose-section h1 {
  font-weight: 500;
  /* margin-bottom: 2rem; */
  font-size: 2.6rem;
  margin: 0;
}
.choose-section h1 span {
  font-weight: 300;
  color: #30d6be;
}
.option-box {
  background: #00000047;
  border-radius: 9px;
  margin-bottom: 2rem;
}
.option-box p {
    color: #b7b7c7;
    line-height: 2;
    font-size: 14px;
    margin: 0;
}
.choose-section a {
  color: white;
  text-decoration: none;
}
.readonly {
  pointer-events: none;
}

/* cookie notice */
#cookit {
  background: #080e23 !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 22px 0px;
  z-index: 9999;
  left: 0;
  right: 0;
}

#cookit p {
  color: #ffffff !important;
  display: inline-block;
  margin: 0;
}

#cookit-button {
  padding: 8px 20px;
  color: #ffffff !important;
  cursor: pointer;
  background: #b13097 !important;
  background-size: 150%;
  border-radius: 36px;
  border: 0 !important;
}

#cookit-link {
  padding: 0px 12px;
  color: #ffffff !important;
  text-decoration: underline;
}
.cookiehidden {
  visibility: hidden;
  opacity: 0;
  display: none;
}


.tips {
  background: none;
  border: 0;
  color: #26c6b4;
  font-size: 16px;
  padding: 0;
  margin-left: 8px;
}

.footer {
  border-top: 1px solid #ffffff1f;
  color: #b7b7c7;
  padding: 28px 0;
}

.footer p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.footer a {
  color: #b7b7c7;
  text-decoration: none;
  font-size: 14px;
}
.footer .row > div:last-child {
  text-align: right;
}
@media (min-width:1025px) {
.choose-section {
  margin-top: 4rem;
}
}
@media (min-width:1180px) {
  .choose-section {
    margin-top: 7rem;
}
}
@media (min-width: 600px) and (max-width: 800px) {
  .choose-section {
    padding-top: 0rem;
  }
  
  .main {
    min-height: 500px;
  }
  }
@media (min-width: 920px) and (max-width: 1170px) {
.choose-section {
  padding-top: 4rem;
}

.main {
  min-height: 610px;
}
}
@media (min-width:1025px) {

.option-box {
  padding: 4rem 4.5rem;
}
/* main nav */
.sidebar-header {
  display: none;
}

.main-nav {
  margin: 0 !important;
  padding: 0 !important;
}

.main-nav li > a {
  color: #fff;
  display: block;
  font-size: 14px;
  padding: 34px 18px !important;
  position: relative
}

.main-nav li a.active,.main-nav li a:hover {
  color: #33d1eb !important;
}
.main-nav li:last-child a {
    padding-right: 0 !important;
}

.main-nav li a i {
    margin-right: 4px;
}
.main-nav li a:before {
  background-image: linear-gradient(90deg,#080a1700 0,#28b1be 51%,#00000000);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  transition: .5s;
  width: 0
}

.main-nav li a.active:before {
  transition: .5s;
  width: 80%
}
/* main nav */


}
@media (min-width:1400px) {
  .container {
    max-width: 1320px;
  }
}
@media (min-width:1300px) {
.main {
  min-height: 780px;
}
}
@media (max-width: 1024px) {
header {
  padding: 16px 8px;
}
.option-box {
  padding: 2rem 20px;
}
.main-nav .navbar-collapse {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: left;
  background: #111525;
  height: 100%;
  max-width: 80%;
  z-index: 3;
  display: block !important;
  left: -100%;
  transition: 0.5s;
}
  .main-nav .navbar-collapse.show {
    left: 0;
}
.navbar-toggler {
  background: transparent;
  color: white;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.main-nav ul li {
    padding: 0px 20px;
}
.main-nav ul li a {
  color: white;
  line-height: 40px;
  padding: 10px 0px;
  border-bottom: 1px solid #ffffff21;
}

.main-nav ul li a.active {
  color: white !important;
}
.sidebar-header {
  align-items: center!important;
  justify-content: space-between!important;
  display: flex!important;
  color: white;
  padding: 12px 20px;
  border-bottom: 1px solid #ffffff21;
}

.sidebar-header .close-button {
  background: none;
  border: 0;
  color: white;
  font-size: 22px;
  color: #22d8e6;
}

.sidebar-header h5 {
  font-size: 20px;
  font-weight: 500;
}
}
@media (max-width: 768px) {
.button-primary {
  width:100%;
  padding: 9px 5px;
}
.page-title h1 {
  font-size: 20px;
}
.card-heading h2 {
  font-size: 18px;
  font-weight: 400;
}
.simulation-tabs .nav-tabs li {
  width: 100%;
  margin-bottom: 10px;
}
.simulation-tabs .nav-tabs li a {
  background: #161d38;
}
body {
  background-position: center center !important;
}
}
@media (max-width:540px) {
.choose-section h1 {
  font-size: 34px;
}
.choose-section h2 {
  margin-bottom: 14px;
}
.option-box p {
  line-height: 23px;
}

}
